import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { useFetchContext } from "../contexts/FetchContext";
import "../css/adminCalendar.css";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { usePostContext } from "../contexts/PostContext";
import { useNavigate } from "react-router-dom";
import Calendar from "../components/Calendar";
import { FaCheck } from "react-icons/fa6";
const CreateClass = () => {
  const { currentColor } = useStateContext();
  const [btnLoading, setBtnLoading] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [morningSlots, setMorningSlots] = useState([]);
  const [afternoonSlots, setAfternoonSlots] = useState([]);
  const [eveningSlots, setEveningSlots] = useState([]);
  const { packages } = useFetchContext();
  const { PostClass } = usePostContext();

  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    setBtnLoading(true);
    await PostClass(values);
    formik.resetForm();
    setBtnLoading(false);
    navigate("/classes");
  };

  const AppointmentSchema = Yup.object({
    packageId: Yup.string().required("Please Select Package"),
    date: Yup.date().required("Please Select Date"),
  });

  const formik = useFormik({
    initialValues: {
      packageId: null,
      date: null,
      morning: [],
      afternoon: [],
      evening: [],
    },
    validationSchema: AppointmentSchema,
    onSubmit: (values) => {
      handleSubmit(values);
      console.log(values);
    },
  });

  const handlePackageChange = (value) => {
    const filteredPackage = packages?.find((packg) => packg._id === value);

    setSelectedPackage(filteredPackage);

    formik.setFieldValue("packageId", value);
  };
  const handleDateChange = (value) => {
    formik.setFieldValue("date", value);
  };

  const addTimeSlot = (time, slotOf) => {
    const currentValues = formik.values[slotOf]; // Get the current array of times for the specified slot

    // Check if the time is already in the array
    const updatedValues = currentValues.includes(time)
      ? currentValues.filter((t) => t !== time) // Remove the time if it exists
      : [...currentValues, time]; // Add the time if it doesn't exist

    // Update the formik field value based on slotOf
    formik.setFieldValue(slotOf, updatedValues);
  };

  const generateTimeSlots = (duration) => {
    const start = moment("09:00 AM", "hh:mm A"); // Start time
    const end = moment("09:00 PM", "hh:mm A"); // End time
    const slots = [];

    while (start.isBefore(end)) {
      slots.push(start.format("hh:mm A"));
      start.add(duration, "minutes"); // Add the duration as an interval
    }

    // Separate slots into morning, afternoon, and evening
    setMorningSlots(
      slots.filter((time) =>
        moment(time, "hh:mm A").isBefore(moment("12:00 PM", "hh:mm A"))
      )
    );
    setAfternoonSlots(
      slots.filter((time) =>
        moment(time, "hh:mm A").isBetween(
          moment("12:00 PM", "hh:mm A"),
          moment("06:00 PM", "hh:mm A"),
          null,
          "[)"
        )
      )
    );
    setEveningSlots(
      slots.filter((time) =>
        moment(time, "hh:mm A").isSameOrAfter(moment("06:00 PM", "hh:mm A"))
      )
    );
  };

  useEffect(() => {
    if (selectedPackage?.duration) {
      generateTimeSlots(selectedPackage.duration);
    }
  }, [selectedPackage]);

  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex items-center justify-between w-full mb-10">
          <h1 className="text-xl md:text-4xl font-medium title-font tracking-widest">
            Create Class
          </h1>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="flex-col mx-auto max-w-3xl"
        >
          <div className="flex items-center gap-5">
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="product" className="sr-only">
                Select Package
              </label>
              <select
                id="product"
                value={formik.values.packageId}
                onChange={(e) => handlePackageChange(e.target.value)}
                onBlur={formik.handleBlur}
                className="block py-2.5 px-0 w-full text-sm text-black-shade bg-transparent border-0 border-b-2 border-black-shade appearance-none dark:text-white-shade dark:border-white-shade focus:outline-none focus:ring-0 focus:border-black-shade peer"
              >
                <option
                  className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                  value=""
                >
                  Select Package
                </option>
                {packages?.map((packg) => (
                  <option
                    className="block py-2.5 px-0 w-full text-sm text-black-shade border-0 border-b-2 border-black-shade dark:border-white-shade"
                    key={packg._id}
                    value={packg._id}
                  >
                    {packg.name}
                  </option>
                ))}
              </select>
              {formik.touched.packageId && formik.errors.packageId && (
                <div className="text-black-shade dark:text-white-shade text-xs mt-1">
                  {formik.errors.packageId}
                </div>
              )}
            </div>
          </div>
          <div className=" min-h-max w-full">
            <Calendar
              onDateChange={handleDateChange}
              minDate={new Date()} // September 10, 2024
              maxDate={
                formik.values.packageId === null
                  ? new Date(moment().day(-1))
                  : null
              }
            />
            {formik.touched.date && formik.errors.date && (
              <div className="text-black-shade dark:text-white-shade mt-1">
                {formik.errors.date}
              </div>
            )}
          </div>
          {formik.values.date && (
            <div className="mt-4 min-h-20 border-black-shade dark:border-white-shade border-2 p-4 rounded-md">
              <div className="my-2">
                <p className="text-3xl font-semibold tracking-widest">Slots</p>
                <p className="text-xl mt-2 font-semibold tracking-widest">
                  Morning
                </p>
                <div className="w-full grid grid-cols-4 gap-x-2 py-2 mt-2">
                  {morningSlots.map((time) => (
                    <button
                      type="button"
                      onClick={() => addTimeSlot(time, "morning")}
                      className="text-center px-2 bg-white justify-center flex items-center gap-x-2 rounded-md py-2 text-black"
                    >
                      {formik.values.morning.includes(time) && <FaCheck />}
                      {time}
                    </button>
                  ))}
                </div>
                <p className="text-xl mt-2 font-semibold tracking-widest">
                  Afternoon
                </p>
                <div className="w-full grid grid-cols-4 gap-2 py-2 mt-2">
                  {afternoonSlots.map((time) => (
                    <button
                      type="button"
                      onClick={() => addTimeSlot(time, "afternoon")}
                      className="text-center px-2 bg-white justify-center flex items-center gap-x-2 rounded-md py-2 text-black"
                    >
                      {formik.values.afternoon.includes(time) && <FaCheck />}
                      {time}
                    </button>
                  ))}
                </div>
                <p className="text-xl mt-2 font-semibold tracking-widest">
                  Evening
                </p>
                <div className="w-full grid grid-cols-4 gap-x-2 py-2 mt-2">
                  {eveningSlots.map((time) => (
                    <button
                      type="button"
                      onClick={() => addTimeSlot(time, "evening")}
                      className="text-center px-2 bg-white justify-center flex items-center gap-x-2 rounded-md py-2 text-black"
                    >
                      {formik.values.evening.includes(time) && <FaCheck />}
                      {time}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="w-full flex justify-end my-6">
            <button
              type="submit"
              style={{ backgroundColor: currentColor }}
              disabled={btnLoading}
              className="text-black-shade disabled:!text-white-shade disabled:!bg-gray-400 dark:disabled:!bg-gray-600 dark:text-white focus:outline-none  font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              {btnLoading ? "Submitting..." : "Create"}
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default CreateClass;

import React from "react";
import { Header } from "../components";
import { useAdminContext } from "../contexts/AdminContext";
import { useMemo } from "react";
import Table from "../components/Table";
import { useStateContext } from "../contexts/ContextProvider";
import { Box, Tooltip, IconButton } from "@mui/material";
import { Send } from "@mui/icons-material";
import { useNavigate, Link } from "react-router-dom";
import Loading from "../components/Loading";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";
const Bookings = () => {
  const { bookings } = useFetchContext();
  const { currentMode, currentColor } = useStateContext();
  const navigate = useNavigate();

  const columns = useMemo(() => [
    {
      accessorKey: "userId.email",
      header: "Client",
      size: 80,
    },
    {
      accessorKey: "selectedSlot.date",
      header: "Date",
      size: 80,
      accessorFn: (row) => <p>{moment(row).format("YYYY-MM-DD")}</p>,
    },
    {
      accessorKey: "packageId.price",
      header: "Amount",
      size: 80,
    },
    {
      accessorKey: "packageId.name",
      header: "Package",
      size: 80,
    },
    {
      accessorFn: (row) =>
        row.status === "confirmed" ? (
          <span className="bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-green-900 dark:text-green-300">
            Confirmed
          </span>
        ) : row.status === "pending" ? (
          <span className="bg-red-100 text-orange-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-orange-900 dark:text-orange-300">
            Pending
          </span>
        ) : (
          <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-red-900 dark:text-red-300">
            Rejected
          </span>
        ),
      accessorKey: "status",
      header: "Status",
      size: 80,
    },
  ]);
  return (
    <div className="m-2 md:m-10 p2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <div className="flex w-full justify-between items-center">
        <Header title="Appointments" />
      </div>
      {bookings === null ? (
        <div className="min-h-[20rem] w-full items-center justify-center flex">
          <Loading
            color={currentMode === "Dark" ? "#20232A" : "#fafafa"}
            bgColor={currentMode === "Dark" ? "#fafafa" : "#20232A"}
          />
        </div>
      ) : (
        <div className="w-full grid">
          <Table
            data={bookings}
            renderRowActions={({ row, table }) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Tooltip arrow placement="right" title="View Details">
                  <IconButton
                    onClick={() => {
                      navigate(`/booking/${row.original._id}`);
                    }}
                  >
                    <Send className="dark:text-white-shade text-black-shade" />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            columns={columns}
          />
        </div>
      )}
    </div>
  );
};

export default Bookings;
